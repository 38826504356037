<template>
  <div
    class="grid grid-cols-1 lg:grid-cols-2 gap-y-8 lg:gap-y-16 px-4 lg:px-8 max-w-screen-lg mx-auto"
  >
    <div class="flex flex-col w-full lg:w-4/5 text-left space-y-4">
      <h1
        class="text-np-glimpse text-np-3xl saolitalic font-light"
        v-html="title"
      ></h1>
      <p
        class="robototext text-np-green content-para font-light space-y-4 text-np-base"
        v-html="description"
      ></p>
      <a
        v-if="exploreLink !== undefined"
        :href="exploreLink"
        class="flex flex-row saoltext neera-text-glimps"
      >
        <div class="flex w-auto items-left pr-2">
          <p>explore</p>
        </div>
        <div class="flex w-auto items-center">
          <svg
            width="16"
            height="16"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M26.2656 11.6406L25.9375 11.3125C25.7031 11.0781 25.375 11.0781 25.1406 11.3125L16 20.4531L6.8125 11.3125C6.57812 11.0781 6.25 11.0781 6.01562 11.3125L5.6875 11.6406C5.45312 11.875 5.45312 12.2031 5.6875 12.4375L15.5781 22.3281C15.8125 22.5625 16.1406 22.5625 16.375 22.3281L26.2656 12.4375C26.5 12.2031 26.5 11.875 26.2656 11.6406Z"
              fill="#994e2e"
            />
          </svg>
        </div>
      </a>
    </div>
  </div>
</template>
<script>
export default {
  name: "Hero",
  props: {
    title: {
      type: [String],
    },
    description: {
      type: [String],
    },
    exploreLink: {
      type: [String],
    },
  },
};
</script>
<style scoped>
.title {
  font-family: "Saol Text" !important;
  font-style: normal;
  font-weight: 300;
  color: #905235;
}

.title sup {
  top: -20px;
}

.booking_button {
  color: #994e2e;
  border: 1px solid #994e2e;
  border-radius: 10px;
}

.menu-content {
  border-top: 1px solid #cecdc8;
}

.underline {
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-offset: 4px;
  text-decoration-thickness: 1px;
}
</style>

<template>
  <div class="experiences" data-view>
    <hero
      title="neera offers"
      description="
    <p class='block break-words'>
      exclusive hotel packages and special
      <span class='whitespace-nowrap saoltext'>‘neera experiences’</span> for your
      perfect stay at <span class='whitespace-nowrap saoltext'>‘neera retreat’</span>.
    </p>
    "
      class="my-18 lg:mb-20 lg:mt-8"
    />
    <tab-menu
      :items="offer_types.map(t => {
        return {
          ...t,
          id: t.attributes.slug
        }
      })"
      all-item-label="all offers"
      :default-tab="0"
      path-name="offers"
      path-id="type"
      :has-all="true"
    />
    <div
      id="offers"
      class="px-4 lg:px-8 max-w-screen-lg mx-auto mb-18 lg:mb-20"
    >
      <TransitionGroup
        name="fade"
        tag="discover-item"
        class="grid grid-cols-1 lg:grid-cols-3 gap-10 lg:gap-x-8 lg:gap-y-10"
      >
        <discover-item
          v-for="(item, index) in offer_list"
          :key="index"
          :image-url="
            getImageByFormat(item.attributes.cardImage || item.attributes.image)
          "
          :item-title="item.attributes.title"
          :item-description="item.attributes.description"
          :badge="item.attributes.type.data.attributes.name"
          :item-detail-path="`/offer/${item.attributes.slug}`"
          :isPin="item.attributes.pin"
        />
      </TransitionGroup>
    </div>
  </div>
</template>

<script>
import { getOfferType, getOfferList } from "@/api/endpoints/offers";
import to from "await-to-js";
import DiscoverItem from "@/components/DiscoverItem.vue";
import Hero from "@/components/Hero.vue";
import TabMenu from "@/components/TabMenu.vue";
import AOS from "aos";
import { getImageUrl } from "@/utils/imageUtils.js";

export default {
  name: "Home",
  components: {
    DiscoverItem,
    Hero,
    TabMenu,
  },
  created() {
    const title = "neera offers";
    const description =
      "exclusive hotel packages and special ‘neera experiences’ for your perfect stay at ‘neera retreat’.";
    // const titleEl = document.querySelector("head title");
    const mTitleEl = document.querySelector('head meta[name="title"]');
    const descEl = document.querySelector('head meta[name="description"]');
    const twTitleEl = document.querySelector('head meta[name="twitter:title"]');
    const twDescEl = document.querySelector(
      'head meta[name="twitter:description"]'
    );
    const ogTitleEl = document.querySelector('head meta[property="og:title"]');
    const ogDescEl = document.querySelector('head meta[property="og:description"]');
    // titleEl.textContent = title;
    mTitleEl.setAttribute("content", title);
    twTitleEl.setAttribute("content", title);
    ogTitleEl.setAttribute("content", title);
    descEl.setAttribute("content", description);
    twDescEl.setAttribute("content", description);
    ogDescEl.setAttribute("content", description);
  },
  data() {
    return {
      lang: "en",
      defaultTabId: 0,
      offer_type_id: 0,
      offer_types: [],
      offer_list: [],
      all_offer_list: [],
    };
  },
  watch: {
    "$route.query.type": async function (offer_type) {
      const offer_type_id = this.offer_types.find(t => t.attributes.slug === offer_type)?.id

      if (offer_type_id) {
        this.offer_list = this.all_offer_list.filter(
          (i) => i.attributes.type.data.id == offer_type_id
        );
      } else {
        this.offer_list = this.all_offer_list;
      }
    },
  },
  mounted() {
    if (this.$route.query.type)
      this.offer_type = this.$route.query.type;

    this.setOfferType(this.lang);
    this.setOfferList(0, this.lang);

    AOS.init({
      once: true,
      duration: 2000,
    });
  },
  methods: {
    getImageByFormat(image) {
      if (image.data) return getImageUrl(image.data);
      return "";
    },
    async setOfferType(lang) {
      const [err, response] = await to(getOfferType(lang));

      if (err) {
        return { errorMessage: err?.response?.data?.error || err.message };
      }

      this.offer_types = response.data.data;
      if (this.defaultTabId == 0 && this.offer_types.length > 0)
        this.defaultTabId = this.offer_types[0].id;
    },
    async setOfferList(typeId, lang) {
      const [err, response] = await to(getOfferList(0, lang));

      if (err) {
        return { errorMessage: err?.response?.data?.error || err.message };
      }
      this.all_offer_list = response.data.data;

      const offer_type_id = this.offer_types.find(t => t.attributes.slug === this.offer_type)?.id

      if (offer_type_id) {
        this.offer_list = this.all_offer_list.filter(
          (i) => i.attributes.type.data.id == offer_type_id
        );
      } else {
        this.offer_list = this.all_offer_list;
      }    
    },
  },
};
</script>

<style scoped>
.title-para {
  font-family: "Saol Text" !important;
  font-style: normal;
  font-weight: 300;
  color: #5d6145;
}

.menu {
  font-family: "Saol Text" !important;
  font-style: normal;
  font-weight: 300;
  color: #5c623f;
}

#offer-content {
  border-top: 1px solid #cecdc8;
}

.discover-more {
  background: #5c623f;
  color: white;
}

.description-special-offer {
  font-style: normal;
  font-weight: 300;
  color: #000000;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
</style>
